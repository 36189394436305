<script setup>
import Multiselect from '@vueform/multiselect'

const props = defineProps({
  errorMessage: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  optionKey: {
    type: String,
    default: '',
  },
  optionValue: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  postfix: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  canDeselect: {
    type: Boolean,
    default: false,
  },
})

const value = defineModel()

const emit = defineEmits(['change', 'select'])
</script>

<style lang="scss">
@import 'assets/css/components/global/nu-dropdown.css';
</style>

<template>
  <div class="nu-dropdown">
    <label
      :for="id"
      class="dropdown-label"
      >{{ label }}
    </label>
    <Multiselect
      :canClear="false"
      :canDeselect="canDeselect"
      :label="optionValue"
      :options="options"
      :placeholder="placeholder"
      :valueProp="optionKey"
      id="nu-dropdown-id"
      v-model="value"
      @change="(val) => $emit('change', val)"
      @select="(val, option) => $emit('select', val, option)"
    >
    </Multiselect>
    <div
      class="error-message"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
